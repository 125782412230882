import React from 'react';

import {
    HeroBanner,
    CaseHeader,
    Carousel,
    DarkMode,
    DeviceType,
    TextContent,
    LightMode,
    Gallery,
    GalleryLayout,
    Team,
} from 'modules/references';
import { sendCaseStudyPageView } from 'utils/analytics';

import socialPreview from '../../../static/social-previews/ivysilani.png';
import Seo from '../../components/SEO';

import { ReferenceCategory } from '../../constants';

const CASE_ID = 'iVysilani';
const MODULE_NAME = 'ivysilani';
const IMAGE_PATH = `references/case-studies/${MODULE_NAME}`;

const colors = {
    bgDark: '#001C36',
};

const team = [
    { department: 'references.case.team.management', names: ['Patrik Šonský', 'Tomáš Marek'] },
    {
        department: 'references.case.team.designReview',
        names: ['Markéta Hejná'],
    },
    {
        department: 'references.case.team.android',
        names: ['Ondřej John', 'Petr Konečný'],
    },
    {
        department: 'references.case.team.ios',
        names: ['Igor Rosocha', 'Jakub Olejník', 'Ondřej Wrzecionko', 'Jan Mísař', 'Dominik Veselý'],
    },
    {
        department: 'references.case.team.testing',
        names: ['Kateřina Tomášková'],
    },
];

const IVysilani = () => (
    <>
        <Seo image={socialPreview} pageId={CASE_ID} handleMetadata={sendCaseStudyPageView} />
        <CaseHeader />
        <HeroBanner
            content={{
                projectName: `case.${CASE_ID}.hero.project`,
                projectTitle: `case.${CASE_ID}.hero.title`,
                projectYear: `case.${CASE_ID}.hero.year`,
                client: `case.${CASE_ID}.hero.client`,
                image: {
                    src: `${IMAGE_PATH}/hero-image.png`,
                    style: { height: '70%', width: '100%', marginTop: '2rem' },
                    imgStyle: { objectFit: 'contain', objectPosition: 'center top' },
                },
            }}
            background={colors.bgDark}
            headerTheme="light"
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section1.name`,
                title: `case.${CASE_ID}.section1.title`,
                text: `case.${CASE_ID}.section1.text`,
                category: ReferenceCategory.DIGITALIZATION,
                outputs: [
                    'references.case.output.designReview',
                    'references.case.output.ios',
                    'references.case.output.android',
                    'references.case.output.testing',
                ],
            }}
        />
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.ONE_HALF}
            fullHeight
            content={[
                {
                    background: colors.bgDark,
                    text: [`case.${CASE_ID}.galleryTitles.darkMode`, `case.${CASE_ID}.galleryTitles.lightMode`],
                    textColor: '#FFFFFF',
                    type: Gallery.GALLERY_ITEM_TYPE.SWITCH,
                    buttons: [
                        {
                            children: <DarkMode />,
                        },
                        {
                            children: <LightMode />,
                        },
                    ],
                    items: [
                        {
                            image: {
                                src: `${IMAGE_PATH}/02_dark.png`,
                                style: {
                                    display: 'block',
                                    margin: 'auto',
                                    objectFit: 'contain',
                                    width: '40%',
                                },
                            },
                        },
                        {
                            image: {
                                src: `${IMAGE_PATH}/02_light.png`,
                                style: {
                                    display: 'block',
                                    margin: 'auto',
                                    objectFit: 'contain',
                                    width: '40%',
                                },
                            },
                        },
                    ],
                },
                {
                    background: 'linear-gradient(180deg, #EEF8FC 0%, #D6EBFF 100%)',
                    image: {
                        src: `${IMAGE_PATH}/03.png`,
                        imgStyle: { width: 'unset' },
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section3.name`,
                title: `case.${CASE_ID}.section3.title`,
                text: `case.${CASE_ID}.section3.text`,
            }}
        />
        <Gallery
            fullHeight
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    background: `linear-gradient(180deg, #EEF8FC 0%, #D6EBFF 100%);`,
                    image: {
                        src: `${IMAGE_PATH}/04.png`,
                        imgStyle: { objectFit: 'contain', objectPosition: 'center bottom' },
                    },
                    extend: {
                        imageContainer: {
                            paddingTop: '5%',
                        },
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section5.name`,
                title: `case.${CASE_ID}.section5.title`,
                text: `case.${CASE_ID}.section5.text`,
            }}
        />
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.ONE_HALF}
            fullHeight
            content={[
                {
                    background: 'linear-gradient(180deg, #EEF8FC 0%, #D6EBFF 100%)',
                    image: {
                        src: `${IMAGE_PATH}/05.png`,
                        imgStyle: {
                            width: '95%',
                            left: 'unset',
                            right: 0,
                            bottom: 0,
                            top: 'unset',
                            height: 'unset',
                        },
                    },
                },
                {
                    background: colors.bgDark,
                    image: {
                        src: `${IMAGE_PATH}/06.png`,
                        imgStyle: {
                            width: '90%',
                            objectPosition: 'center',
                            left: '50%',
                            transform: 'translateX(-50%)',
                        },
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section7.name`,
                title: `case.${CASE_ID}.section7.title`,
                text: `case.${CASE_ID}.section7.text`,
            }}
        />

        <Carousel
            headerTheme="dark"
            deviceType={DeviceType.DESKTOP}
            background={colors.bgDark}
            slides={[
                {
                    image: {
                        src: `${IMAGE_PATH}/07.png`,
                        alt: `case.${CASE_ID}.galleryTitles.webApp`,
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/08.png`,
                        alt: `case.${CASE_ID}.galleryTitles.webApp`,
                    },
                },
            ]}
        />

        <Team headerTheme="dark" content={team} />

        {/* Uncomment when ready (fonts omfg) */}
        {/* <Testimonial
                headerTheme="dark"
                background="#F9F9F9"
                content={{
                    quote: `case.${CASE_ID}.testimonial.text`,
                    person: {
                        name: `case.${CASE_ID}.testimonial.name`,
                        role: `case.${CASE_ID}.testimonial.position`,
                        image: {
                            src: `${IMAGE_PATH}/img_person_flashnews.jpg`,
                        },
                    },
                }}
                type="PROFILE_RIGHT"
            />

           */}
    </>
);

export default IVysilani;
